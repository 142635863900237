import React from "react";
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from "../components/Layout";
import NavigationBar from "../components/NavigationBar";
import ProfilePicture from "../components/ProfilePicture";
import Seo from "../components/Seo";
import { Link } from "gatsby";
import Img from 'gatsby-image';
import Footer from "../components/Footer";
import NewsletterSignUp from "../components/NewsletterSignUp";


const BlogPost = ({ pageContext }) => {
    const { blogTitle, blogCategory, blogDate, featureImage, imageAlt, body } = pageContext;

    function getDateString(date) {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let pattern = /(\d{2})-(\d{2})-(\d{4})/;
        let dateStr = date.toString();
        let processedDate = new Date(dateStr.replace(pattern, '$3-$2-$1'));
        return months[processedDate.getMonth()] + " " + processedDate.getFullYear()
    }

    const processedDate = getDateString(blogDate);

    const contentTailwindClasses = "prose prose-p:text-justify prose-ol:text-2xl prose-ul:text-lg prose-li:font-light";

    return (
        <Layout>
            <Seo title={blogTitle}/>
            <NavigationBar />
            <div className="flex mobile:flex-col tablet:flex-col">
                <div className="w-full desktop:w-1/2">
                    <div className="flex flex-wrap mb-6 desktop:pt-10 tablet:flex-col">
                        <div className="flex items-center justify-center mb-8">
                            <div className="flex items-center justify-center text-xs text-gray-500 font-semibold">
                                <Link to="/">Home</Link>
                                <span className="inline-block px-1">/</span>
                                <Link to="/articles">Blog</Link>
                                <span className="inline-block px-1">/</span>
                                <Link to="#">{blogCategory}</Link>
                            </div>
                        </div>
                        <h1 className="mb-10 text-4xl font-semibold text-center desktop:text-left">{blogTitle}</h1>
                        <div className="flex items-center justify-center">
                            <div className="mr-6">
                                <ProfilePicture />
                            </div>
                            <div className="text-left">
                                <h3 className="mb-2 text-2xl font-semibold font-heading">Simon Dunkelman</h3>
                                <p className="text-gray-500">{processedDate}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="desktop:w-1/2 desktop:mt-20 desktop:pl-8">
                    <Img fixed={featureImage} alt={imageAlt} className="max-w-full" />
                </div>
            </div>

            <div className="py-10">
                <div className="flex justify-center px-2">
                    <div className={contentTailwindClasses}>
                        <MDXRenderer>{body}</MDXRenderer>
                    </div>
                </div>
            </div>

            <NewsletterSignUp headline="Stay ahead of the curve" tagline="Join our newsletter to be the first to read insights, guides & more." />
            <Footer />
        </Layout>
    );
}

export default BlogPost;