import React, {useRef, useState} from "react";
import emailjs from '@emailjs/browser';

function NewsletterSignUp(props) {
    const [formSent, setFormStatus] = useState(false);

    const form = useRef();
    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_9aw8f4w', 'template_2xywp7n', form.current, 'kiLBh2A-TZdJSwRnM')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        emailjs.sendForm('service_9aw8f4w', 'template_ryn174k', form.current, 'kiLBh2A-TZdJSwRnM')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
        setFormStatus(true);
    }

    return (
        <section className="flex flex-col space-y-4 h-72 bg-secondary justify-center items-center px-6">
            <h2 className="font-bold text-4xl">{props.headline}</h2>
            <p className="text-lg">{props.tagline}</p>
            <form ref={form} onSubmit={sendEmail} className="flex mobile:flex-col items-center max-w-md" method="POST">
                <input name="email" type="email" placeholder="Type your e-mail" className="mr-2 mobile:mb-2 w-72 px-4 py-3 bg-white rounded" />
                <input type="submit" value="Subscribe" className="inline-block px-6 py-2 text-sm text-yellow font-bold leading-loose bg-midnight hover:text-secondary rounded transition duration-200" />
            </form>
            <p className={`text-lg font-extralight ${formSent ? 'visible' : 'invisible'}`}>Subscribed! Please check your inbox for confirmation (or spam if not received).</p>
        </section>
    )
}

export default NewsletterSignUp